import UploadItem from "../UploadItems";

import { Select } from 'antd';
import { ReactComponent as IconArrowDown } from '../../assets/images/icons/icon-arrow-down.svg';
import { useEffect, useState } from "react";

const { Option } = Select;


const FileContentUpload = ({ file, editaAdicionarRemover, editarTipoDocumento, handlePreview, handleRemove, handleDownload, handleType, proposta, tipoAnexos }) => {

    const [tiposAnexosFiltrados, setTiposAnexosFiltrados] = useState([]);

    const filterTipoDocumentoCorban = (id) => {
        const tipos = tipoAnexos?.filter((arquivo) => {
            if (
                (proposta?.operacao?.produtoId === arquivo.produtoId &&
                    (arquivo.tipoModalidade ===
                        proposta?.operacao?.tipoModalidade ||
                        arquivo.tipoModalidade === 0) &&
                    (arquivo.tipoRenda === proposta?.operacao?.tipoRenda ||
                        arquivo.tipoRenda === null)) &&
                arquivo.exibirCorban
            ) {
                return arquivo;
            }else{
                return null
            }
        });

        const existeTipoDocumentoSelecionado = tipos.find(arquivo => arquivo.id === id);

        if (!existeTipoDocumentoSelecionado) {
            tipos.push(tipoAnexos?.find(arquivo => arquivo.id === id));
        }

        setTiposAnexosFiltrados(tipos);
    }

    useEffect(() => {
        filterTipoDocumentoCorban(file.documentoId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editaTipoDocumento = () => {
        if ((file.exclusao || file?.agente === "crefaz") && editaAdicionarRemover) {
            return <>
                <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    id="tipoArquivo"
                    name="tipoArquivo"
                    defaultValue={file.documentoId}
                    suffixIcon={<IconArrowDown />}
                    placeholder="Tipo de Arquivo"
                    dropdownClassName="dropdown-select-upload-proposta-credito "
                    onChange={(value) => {
                        handleType(value, file);
                    }}
                    key={`select-type-id-${file.imagemId}`}
                >
                    {tipoAnexos?.map((arquivo) => {
                        if (
                            (proposta?.operacao?.produtoId === arquivo.produtoId &&
                                (arquivo.tipoModalidade ===
                                    proposta?.operacao?.tipoModalidade ||
                                    arquivo.tipoModalidade === 0) &&
                                (arquivo.tipoRenda === proposta?.operacao?.tipoRenda ||
                                    arquivo.tipoRenda === null))
                            &&
                            arquivo.exibirCrefaz
                        ) {
                            return (
                                <Option key={arquivo.id} value={arquivo.id}>
                                    {arquivo.nome}
                                </Option>
                            );
                        }else{
                            return null;
                        }

                    })}
                </Select>
            </>
        } else if (file?.agente === "corban" && editarTipoDocumento) {
            return <>
                <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    id="tipoArquivo"
                    name="tipoArquivo"
                    defaultValue={file.documentoId}
                    suffixIcon={<IconArrowDown />}
                    placeholder="Tipo de Arquivo"
                    dropdownClassName="dropdown-select-upload-proposta-credito "
                    onChange={(value) => {
                        handleType(value, file);
                        filterTipoDocumentoCorban(value);
                    }}
                    key={`select-type-id-${file.imagemId}`}
                >
                    {tiposAnexosFiltrados?.map(arquivo => (
                        <Option key={arquivo.id} value={arquivo.id}>
                            {arquivo.nome}
                        </Option>
                    ))}
                </Select>
            </>
        } else {
            return <>
                <div className="titulo-arquivo">
                    <h5>
                        {tipoAnexos?.find(
                            (tipo) => tipo.id === file.documentoId,
                        )?.nome || 'OUTROS'}
                    </h5>
                </div>
            </>
        }
    }

    return (
        <>
            <div class="icone-arquivo">
                {UploadItem(file, handlePreview, handleRemove, handleDownload)}
            </div>

            {editaTipoDocumento()}

            <p className="data-upload-item">{file.dataHora}</p>
        </>
    );
}

export default FileContentUpload;