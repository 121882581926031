import api from './api';

  export const listarProdutos = async () => {
  let produtos = sessionStorage.getItem("produtos");

  if (!produtos) {
    try {
      const { data } = await api.get('/Produto');
      sessionStorage.setItem("produtos", JSON.stringify(data.data));
      return data.data;
    } catch (error) {
      return error;
    }
  }
    produtos = JSON.parse(produtos);
    return produtos;  
  };




export const listarDocumentosImpressao = async (produtoId) => {
  try {
    const { data } = await api.get(`/Produto/documentoImpressao/${produtoId}`);
    return data.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const listarBoletosImpressao = async (produtoId) => {
  try {
    const { data } = await api.get(`/proposta/impressaoBoleto/${produtoId}`);
    return data.data;
  } catch (error) {
    return error?.response?.data;
  }
};
