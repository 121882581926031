import React, { useState, createContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../components/Modal';

import {
  getDocumento,
  getBoleto,
  getPropostaData,
} from '../services/impressaoDocumentosService';

import {
  listarProdutos,
  listarDocumentosImpressao,
  listarBoletosImpressao,
} from '../services/produtoService';

export const ImpressaoDocumentosContext = createContext({
  loading: false,
  produtos: [],
  proposta: {},
  produtoSelecionado: null,
  documentos: [],
  imprimirDocumento: (f) => f,
  imprimirBoleto: (f) => f,
  setProdutoSelecionado: (f) => f,
});

function ImpressaoDocumentosProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [boletos, setBoletos] = useState([]);
  const [proposta, setProposta] = useState(null);

  const params = useParams();
  const propostaId = params?.propostaId;

  useEffect(() => {
    async function fetchPropostaData() {
      setLoading(true);
      await getPropostaData(propostaId).then((response) => {
        setProposta(response.data);
        setLoading(false);
      });
    }
    if (propostaId) {
      fetchPropostaData();
    }
  }, [propostaId]);

  useEffect(() => {
    async function loadProdutos() {
      const data = await listarProdutos();
      setProdutos(data.filter((produto) => produto.ativo));
    }

    loadProdutos();
  }, []);

  useEffect(() => {
    setDocumentos([]);
    const produto = produtoSelecionado || proposta?.produtoId;

    async function loadDocumentos() {
      setLoading(true);
      var data = await listarDocumentosImpressao(produto);
      if (data) {
        setDocumentos(data);
      }

      if (propostaId) {
        data = await listarBoletosImpressao(propostaId);
        if (data) {
          setBoletos(data);
        }
      } else {
        setBoletos([]);
      }

      setLoading(false);
    }
    if (produto) {
      loadDocumentos();
    }
  }, [produtoSelecionado, proposta?.produtoId, propostaId]);

  const imprimirPDF = (file) => {
    const url = window.URL.createObjectURL(
      new Blob([file.data], { type: 'application/pdf' }),
    );

    let fileName = file.headers?.['content-disposition']?.split(/=|;/)[2];

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();

    return file;
  };

  const imprimirDocumento = async ({
    tipoDocumento,
    produtoId = '',
    propostaId = '',
  }) => {
    setLoading(true);

    const file = await getDocumento({ propostaId, produtoId, tipoDocumento });
    if (file?.success === false) {
      Modal('', 'Falha ao baixar o arquivo.', 'error');
    } else {
      imprimirPDF(file);
    }

    setLoading(false);
  };

  function base64ToArrayBuffer(base64) {
    var binary_string = atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const imprimirBoleto = async (linkBoleto) => {
    setLoading(true);

    await getBoleto(linkBoleto).then(async (response) => {
      const file = base64ToArrayBuffer(response.data.boleto);

      const url = window.URL.createObjectURL(
        new Blob([file], { type: 'application/pdf' }),
      );

      let fileName = 'Boleto_' + propostaId;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    setLoading(false);
  };

  return (
    <ImpressaoDocumentosContext.Provider
      value={{
        loading,
        produtos,
        proposta,
        produtoSelecionado,
        documentos,
        boletos,
        imprimirDocumento,
        setProdutoSelecionado,
        imprimirBoleto,
        propostaId,
      }}
    >
      {children}
    </ImpressaoDocumentosContext.Provider>
  );
}

export default ImpressaoDocumentosProvider;
