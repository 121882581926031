import React, { createContext, useEffect, useState } from 'react';

import ModalAlerta from '../components/Modal';
import { validationSchema } from '../../src/pages/DadosCredenciado/Tabs/validationSchema';
import { useFormik } from 'formik';

import validaCPF from '../utils/validaCpf';
import validaPhone from '../utils/validaPhone';
import validaCNPJ from '../utils/validaCnpj';
import {
  getHierarquia,
  getPermissaoCadastroCpfCnpj,
  getContextoCredenciamento,
  getCredenciadoArvore,
  getHierarquiaCargos,
  postCredenciadoLista,
  postCredenciadoProduto,
  getInformacoesUsuario,
  getUnidadeInfos,
  postCredenciadoUser,
  putCredenciadoUser,
  getDadosBanco,
  getPermissaoCadastroUnidadeCnpj,
  getCredenciadoConveniadoArvore,
  getUnidadeConvenioInfos,
} from '../services/credenciadoService';
import { useParams } from 'react-router-dom';
import removeChars from '../utils/removeChars';
import { listarUfs, listarCidades } from '../services/enderecoService';
import { getDDDs } from '../services/contatoService'
import dddList from '../utils/ddds';
import {
  SubTipoConvenio,
  TipoCredenciado,
} from '../utils/identificadoresFrontBack';

export const CredenciadoContext = createContext({});

function CredenciadoProvider({ children }) {
  const params = useParams();
  const userId = params?.userId;
  const initialValues = {
    id: null,
    statusCredenciado: 0,
    dadosGerais: {
      ativo: true,
      unicard: false,
      analisePromotora: false,
      tipoCredenciado: null,
      subTipoCredenciado: null,
      classificacaoCredenciado: [],
      cpfCnpj: null,
      nome: null,
      dataCadastro: null,
      dataAlteracao: null,
      analiseOCRFatura: null
    },
    endereco: {
      cep: null,
      logradouro: null,
      numero: null,
      ufId: null,
      cidadeId: null,
      bairro: null,
      complemento: null,
    },
    regiao: {
      opera: true,
      ufId: null,
      cidadeId: null,
      id: null,
    },
    contato: [
      {
        nome: null,
        email: null,
        telefone: null,
        tipoContato: null,
        observacao: null,
      },
    ],
    visita: {
      visita: false,
      dataVisita: null,
      horaVisita: null,
      contato: null,
      anexos: [],
      dataHora: null,
    },
    socios: [
      {
        cpf: null,
        nome: null,
        pep: false,
      },
    ],
    dadosBancarios: {
      banco: null,
      agencia: null,
      agenciaDigito: null,
      contaNumero: null,
      conta: null,
      tipoConta: null,
      tipoOperacao: null,
      tempoConta: null,
      tipoChavePix: null,
      chavePix: null,
    },
    produtos: {
      produtos: [
        {
          modalidadeOpcoes: null,
          id: null,
          propriedade: {
            nome: '',
            opcoes: [],
          },
        },
      ],
      tipoModalidade: null,
    },
    comercial: {
      hierarquia: {
        dataVigenciaInicial: '',
        id: null,
        niveis: [{ id: null, posicaoId: null }],
      },
      historico: {},
    },
    regioes: [],
    documentos: [],
    dadosConsignado: {
      restrito: false,
      diaCorte: null,
      diaVencimento: null,
      turnOver: 0,
      percentualCalculoMargem: null,
      convenioRiscoId: null,
      quantidadeVidas: null,
      bancoConcorrente: [],
    },
  };
  const [credenciamentoHierarquias, setCredenciamentoHierarquias] = useState(
    [],
  );

  const [hierarquiasAtualizado, setHierarquiasAtualizado] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [unidadeInfos, setUnidadeInfos] = useState([]);
  const [lstRandomIdsUnidadesTree, setLstRandomIdsUnidadesTree] = useState([]);
  const [valueHierarquia, setValueHierarquia] = useState(null);
  const [showVigencias, setShowVigencias] = useState(null);
  const [hierarquiasUnidades, setHierarquiasUnidades] = useState([]);
  const [produtosRegiao, setProdutosRegiao] = useState([]);
  const [cpfCnpjUnidadesCadastradas, setCpfCnpjUnidadesCadastradas] = useState(
    [],
  );

  const [regioesTodosEstados, setRegioesTodosEstados] = useState([]);
  const [enviaDadosUnidades, setEnviaDadosUnidades] = useState(false);  
  const ddds = sessionStorage.getItem('ddds');
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  const fetchCidades = async (ufId) => {
    const storedCidades = JSON.parse(sessionStorage.getItem('cidades')) || {};
    if (storedCidades[ufId]) {
      setCidades(storedCidades[ufId]);
    } else {
      const response = await listarCidades(ufId);
      const updatedCidades = { ...storedCidades, [ufId]: response };
      setCidades(response);
      sessionStorage.setItem('cidades', JSON.stringify(updatedCidades));
    }
  };

  const fetchEstados = async () => {
    const storedEstados = JSON.parse(sessionStorage.getItem('endereco-ufs'));
    if (storedEstados) {
      return storedEstados;
    } else {
      const response = await listarUfs();
      sessionStorage.setItem('endereco-ufs', JSON.stringify(response));
      return response;
    }
  };

  useEffect(() => {
    fetchEstados().then(setEstados);
  }, []);

  const populaRegioes = () => {
    if (regioesTodosEstados.length === 0) {
      listarUfs().then((estados) => {
        const regioes = estados.map((e) => {
          return {
            opera: true,
            ufId: e.id,
            ufNome: e.nome,
            cidadeId: null,
            id: null,
          };
        });
        setRegioesTodosEstados(regioes);
        setFieldValue('regioes', regioes);
      });
    } else {
      setFieldValue('regioes', regioesTodosEstados);
    }
  };

  const [visibleDatePickerComercial, setVisibleDatePickerComercial] =
    useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;

  function actionGetHierarquia(active = true) {
    if (credenciamentoHierarquias?.length === 0) {
      getHierarquia(active).then((response) => {
        if (response) {
          setCredenciamentoHierarquias(response);
        }
      });
    }
  }

  function actionGetHierarquiaCargos(id, selectId, hierarquiaNivelDetalheId) {
    const hierarquiaNivelId =
      values?.comercial?.hierarquia?.niveis[selectId]?.id || null;

    getHierarquiaCargos(id, hierarquiaNivelId, hierarquiaNivelDetalheId).then(
      (response) => {
        const responseHierarquiaNivel = response?.hierarquiaNivel;
        const responseHierarquiaNivelProximo = response?.proximoHierarquiaNivel;
        if (response) {
          if (hierarquiaNivelId && hierarquiaNivelDetalheId) {
            if (responseHierarquiaNivelProximo) {
              const newList = [...values?.comercial];
              newList.splice(selectId + 1);
              newList[selectId]['selected'] = hierarquiaNivelDetalheId;
              responseHierarquiaNivelProximo.selected = null;
              newList.push(responseHierarquiaNivelProximo);
              setFieldValue('comercial.selectsNiveis', newList);
              setVisibleDatePickerComercial(false);
            } else {
              setVisibleDatePickerComercial(true);
            }
          } else {
            if (values?.hierarquiaId) {
              responseHierarquiaNivel.selected = null;
              setFieldValue('comercial.responseHierarquiaNivel', [
                responseHierarquiaNivel,
              ]);
            } else {
              responseHierarquiaNivel.selected = null;
              setFieldValue('comercial.responseHierarquiaNivel', [
                responseHierarquiaNivel,
              ]);
            }
          }
        }
      },
    );
  }

  const [unidades, setUnidades] = useState([]);
  const [AllUnidades, setAllUnidades] = useState([]);
  const [unidadeId, setUnidadeId] = useState(null);

  function adicionaGrupoSearch(array, group) {
    array.push({
      id: group.id,
      nome: group.nome,
      ativo: group.ativo,
      subUnidade: [],
      cpfCnpj: group.cpfCnpj,
    });
    return array.length - 1;
  }
  async function isCnpjValid(cnpj, tipo, subtipo) {
    const response = await getPermissaoCadastroCpfCnpj(cnpj, tipo, subtipo);
    return response.status;
  }

  async function isCpfCnpjUnidadeValid(cnpj, tipo, subtipo) {
    const response = await getPermissaoCadastroUnidadeCnpj(cnpj, tipo, subtipo);
    return response;
  }

  function actionGetPermissaoCadastroCpfCnpj(cpfCnpj, tipo, subtipo) {
    return getPermissaoCadastroCpfCnpj(cpfCnpj, tipo, subtipo);
  }

  const { getFieldMeta, getFieldHelpers } = formik;
  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);
    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }
  function validaDuasPalavras(event, nomeCampo) {
    if (event.target.value) {
      const palavras = event.target.value.trim().split(' ');
      if (palavras.length < 2) {
        ModalAlerta(
          '',
          'Informe ao menos duas palavras para o campo ' + nomeCampo,
          'warning',
          () => {
            event.target.focus();
          },
        );
      }
    }
  }
  const validationField = (value, name, label, type) => {
    let msgError = null;
    if (value) {
      switch (type) {
        case 'email':
          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
            msgError = 'Deve ser informado um e-mail válido';
          }
          break;
        case 'telefone':
          if (!validaPhone(value)) {
            msgError = 'Telefone Inválido';
          }
          break;
        case 'cpf':
          if (!validaCPF(value?.replace(/\D/g, ''))) {
            msgError = 'CPF Inválido';
          }
          break;
        case 'cnpj':
          if (!validaCNPJ(value?.replace(/\D/g, ''))) {
            msgError = 'CNPJ Inválido';
          }
          break;
        case 'nome':
          if (!value.match(/(\w.+\s).+/)) {
            msgError = 'Nome Incompleto';
          }

          if (value.match(/\d+/)) {
            msgError = 'Nome Inválido';
          }
          break;
        default:
      }
    }
    return msgError;
  };

  const isConveniadaConsigPrivado =
    formik?.values?.dadosGerais?.tipoCredenciado ===
    TipoCredenciado.CONVENIO_ID &&
    formik?.values?.dadosGerais?.subTipoCredenciado ===
    SubTipoConvenio.CONSIGNADO_PRIVADO_ID;

  async function handlePesquisar(values, source) {
    if (userId && credenciadoArvore.length === 0) {
      let listagemAux = credenciadoArvore;
      let data;
      if (!isConveniadaConsigPrivado && infoUsuario.correspondenteId) {
        data = await fetchArvoreUnidades(infoUsuario.correspondenteId);
      } else {
        data = await fetchArvoreUnidadesConvenio(infoUsuario.convenioId);
      }

      setCredenciadoArvore(data);
      listagemAux = [...data, ...unidades];
      if (source === 'btn') {
        const novo = findNovo(listagemAux);
        if (novo?.novo) {
          novo.novo = false;
        }
      }
      setUnidades(listagemAux);
      setUnidadesFiltradas(filtraListagem(values, listagemAux));
      setFiltroAtual(values);
      return filtraListagem(values, listagemAux).length ? true : false;
    } else {
      const newUnidades = unidades;
      if (source === 'btn') {
        const novo = findNovo(newUnidades);
        if (novo?.novo) {
          novo.novo = false;
        }
      }
      setFiltroAtual(values);
      setUnidadesFiltradas(filtraListagem(values, newUnidades));
      return filtraListagem(values, newUnidades).length ? true : false;
    }
  }

  function filtraListagem(values, unidades) {
    let tempArray = [];
    if (values?.unicaUnidade) {
      return [unidades.find((item) => item.id === values.unicaUnidade)];
    }
    unidades?.forEach((unidade) => {
      let filtroCNPJFormatado = removeChars(
        ['/', '-', '.'],
        values.filtroCPFCNPJ,
      );
      let ix = -1;
      if (
        (filtroCNPJFormatado
          ? unidade?.cpfCnpj?.match(
            filtroCNPJFormatado ? filtroCNPJFormatado : unidade.cpfCnpj,
          )
          : true) &&
        (values.filtroNome
          ? unidade?.nome
            ?.toUpperCase()
            ?.match(
              values.filtroNome
                ? values.filtroNome.toUpperCase()
                : unidade.nome.toUpperCase(),
            )
          : true) &&
        (values.filtroAtivo !== null
          ? unidade?.ativo === values.filtroAtivo
          : true)
      ) {
        ix = adicionaGrupoSearch(tempArray, unidade);
      }

      if (unidade?.subUnidade?.length > 0) {
        const data = percorreSubunidade(values, unidade?.subUnidade);
        if (data.length > 0) {
          if (ix < 0) ix = adicionaGrupoSearch(tempArray, unidade);
          adicionaSubunidadeSearch(tempArray, data, ix);
        }
      }
    });
    return tempArray;
  }
  function adicionaSubunidadeSearch(array, group, index) {
    array[index].subUnidade = group;
    return array.length - 1;
  }
  function percorreSubunidade(values, subunidades) {
    let tempArraySubunidade = [];
    let tempArrayUnidade = [];

    subunidades?.forEach((item) => {
      let ix = -1;
      if (item?.subUnidade?.length > 0) {
        let filtroCNPJFormatado = removeChars(
          ['/', '-', '.'],
          values.filtroCPFCNPJ,
        );
        if (
          ((filtroCNPJFormatado
            ? item?.cpfCnpj?.match(
              filtroCNPJFormatado ? filtroCNPJFormatado : item.cpfCnpj,
            )
            : true) &&
            (values.filtroNome
              ? item?.nome
                ?.toUpperCase()
                ?.match(
                  values.filtroNome
                    ? values.filtroNome.toUpperCase()
                    : item.nome.toUpperCase(),
                )
              : true) &&
            (values.filtroAtivo !== null
              ? item?.ativo === values.filtroAtivo
              : true)) ||
          item.novo
        ) {
          ix = adicionaGrupoSearch(tempArrayUnidade, item);
        }

        const data = percorreSubunidade(values, item?.subUnidade);

        if (data.length > 0) {
          if (ix < 0) ix = adicionaGrupoSearch(tempArrayUnidade, item);
          adicionaSubunidadeSearch(tempArrayUnidade, data, ix);
        }
      } else {
        if (
          ((values.filtroCPFCNPJ
            ? item?.cpfCnpj?.match(
              values.filtroCPFCNPJ ? values.filtroCPFCNPJ : item.cpfCnpj,
            )
            : true) &&
            (values.filtroNome
              ? item?.nome
                ?.toUpperCase()
                ?.match(
                  values.filtroNome
                    ? values.filtroNome.toUpperCase()
                    : item.nome.toUpperCase(),
                )
              : true) &&
            (values.filtroAtivo !== null
              ? item?.ativo === values.filtroAtivo
              : true)) ||
          item.novo
        ) {
          tempArraySubunidade.push(item);
          return item;
        }
      }
    });

    return [...tempArrayUnidade, ...tempArraySubunidade];
  }

  const abasAcessiveis = [
    { id: '1', nome: 'Dados Gerais', key: 'dadosGerais' },
    { id: '2', nome: 'Endereço', key: 'endereco' },
    { id: '3', nome: 'Contato', key: 'contato' },
    { id: '4', nome: 'Visita', key: 'visita' },
    { id: '5', nome: 'Sócios', key: 'socios' },
    { id: '6', nome: 'Comercial', key: 'comercial' },
    { id: '7', nome: 'Dados Bancários', key: 'dadosBancarios' },
    { id: '8', nome: 'Documentos', key: 'documentos' },
    { id: '12', nome: 'Consignado Privado', key: 'dadosConsignado' },
    { id: '9', nome: 'Regiões', key: 'regioes' },
    { id: '10', nome: 'Produtos', key: 'produtos' },
    { id: '11', nome: 'Unidades', key: 'unidades' },
  ];

  // Aba Contatos

  const initialValuesContato = [
    {
      nome: null,
      email: null,
      telefone: null,
      tipo: null,
      observacoes: null,
      id: null,
    },
  ];
  const [contatoInputs, setContatoInputs] = useState(initialValuesContato);

  // Aba Socios
  const initialValuesSocios = [
    {
      cpf: null,
      nome: null,
      pep: false,
    },
  ];
  const [sociosInputs, setSociosInputs] = useState(initialValuesSocios);
  //Aba Comercial
  const initialValuesComercial = {
    comercial: [],
  };
  const formikComercial = useFormik({
    initialValues: initialValuesComercial,
    enableReinitialize: true,
  });
  // Aba Documentos
  const [fileListDocumentos, setFileListDocumentos] = useState([]);
  const [unidadesFiltradas, setUnidadesFiltradas] = useState([]);
  // Aba Visita
  const [fileList, setFileList] = useState([]);
  const [filtroAtual, setFiltroAtual] = useState([]);
  const [dadosBancoContext, setDadosBancoContext] = useState();

  const [credenciadoArvore, setCredenciadoArvore] = useState([]);

  const [visibleModalUnidades, setVisibleModalUnidades] = useState(false);
  const [dadosCredenciamentoContext, setDadosCredenciamentoContext] = useState(
    {},
  );
  const [listaPesquisa, setPesquisa] = useState([]);
  const [propsListagem, setPropsListagem] = useState({
    quantityPerPage: 0,
    total: 0,
    totalPages: 0,
  });
  const [infoUsuario, setInfoUsuario] = useState({});
  const [visibleModalDiscard, setVisibleModalDiscard] = useState(false);


  async function pesquisarCredenciado(payload) {
    await postCredenciadoLista(payload).then((response) => {
      setPesquisa(response.data?.itens || []);
      setPropsListagem({
        total: response?.data?.total || 0,
        totalPages: response?.data?.totalPages || 0,
        quantityPerPage: response?.data?.quantityPerPage || 0,
      });
    });
  }
  async function fetchInformacoesBancos() {
    const response = await getDadosBanco();
    setDadosBancoContext(response.data);
  }

  const loadContextCredenciado = async () => {
    getContextoCredenciamento().then((response) => {
      setDadosCredenciamentoContext(response);
    });
    fetchInformacoesBancos();
  };

  async function fetchArvoreUnidades(userId, search) {
    return await getCredenciadoArvore(userId, search).then((response) => {
      if (response) {
        return response;
      }
    });
  }

  async function fetchArvoreUnidadesConvenio(userId, search) {
    return await getCredenciadoConveniadoArvore(userId, search).then(
      (response) => {
        if (response) {
          return response;
        }
      },
    );
  }

  function findNovo(list) {
    let unidade = list.find((children) => children.novo) || '';
    if (unidade === '' && list.length > 0) {
      list.forEach((item) => {
        if (item.subUnidade) {
          let aux = findNovo(item.subUnidade);
          if (aux !== '') {
            unidade = aux;
          }
        }
      });
    }
    return unidade;
  }
  function findUnidade(list, id, type = false) {
    let unidade = list.find((children) => children.id === id) || '';
    if (unidade === '' && list.length > 0) {
      list.forEach((item) => {
        if (item.subUnidade) {
          let aux = findUnidade(item.subUnidade, id);
          if (aux !== '') {
            if (type) {
              unidade = item;
            } else {
              unidade = aux;
            }
          }
        }
      });
    }
    return unidade;
  }

  function swapElement(parent, searchItem, swapContent) {
    // troca o searchItem pelo swapContent dentro do parent
    if (parent.id === searchItem.id) {
      return swapContent;
    }

    if (parent?.subUnidade?.length) {
      parent.subUnidade = parent.subUnidade.map((item) => {
        return swapElement(item, searchItem, swapContent);
      });
    }

    return parent;
  }

  function inativaSubunidades(parentList) {
    parentList = parentList?.map((unidade) => {
      unidade.ativo = false;
      if (unidade?.subUnidade?.length) {
        unidade.subUnidade = unidade.subUnidade.map((item) => {
          item.subUnidade = inativaSubunidades(item.subUnidade);
          item.ativo = false;
          return item;
        });
      }
      return unidade;
    });

    return parentList || null;
  }

  async function fetchInfoUsuario(userId) {
    const response = await getInformacoesUsuario(userId);

    if (
      response?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      response?.dadosGerais?.subTipoCredenciado ===
      SubTipoConvenio.CONSIGNADO_PRIVADO_ID &&
      response?.dadosConsignado
    ) {
      response.dadosConsignado = response?.dadosConsignado[0];
      response.dadosConsignado.bancoConcorrente = response.dadosConsignado
        .bancoConcorrente
        ? response?.dadosConsignado?.bancoConcorrente.map(
          (banco) => banco.codigo,
        )
        : [];
    }

    const ufId = response?.endereco?.ufId
    if (ufId > 0) {
      fetchCidades(ufId)
    }     

    setInfoUsuario({
      ...response,
      regioes: response.regioes || [],
      socios: response.socios || [],
      dadosBancarios: response.dadosBancarios || {},
      produtos: {
        ...response.produtos,
        produtos: response.produtos?.produtos || [],
      },
      dadosConsignado: response.dadosConsignado || {
        restrito: false,
        diaCorte: null,
        diaVencimento: null,
        turnOver: 0,
        percentualCalculoMargem: null,
        convenioRiscoId: null,
        quantidadeVidas: null,
        bancoConcorrente: [],
      },
    });
  }
  const postProdutoRegiao = async (payload) => {
    const data = await postCredenciadoProduto(payload);
    return data;
  };

  const fetchUnidadesInfo = async (unidadeId) => {
    let data;
    if (isConveniadaConsigPrivado) {
      data = await getUnidadeConvenioInfos(unidadeId);
    } else {
      data = await getUnidadeInfos(unidadeId);
    }
    return data;
  };

  const validaCidadeUfUnidades = (payload) => {
    payload.unidades = payload?.unidades?.map((unidade) => {
      if (unidade?.endereco?.cidadeId === '') unidade.endereco.cidadeId = null;

      if (unidade?.endereco?.ufId === '') unidade.endereco.ufId = null;

      return unidade;
    });
    return payload;
  };

  async function criarCredenciado(payload) {
    payload = validaCidadeUfUnidades(payload);
    const data = await postCredenciadoUser(payload);
    return data;
  }

  async function editarCredenciado(payload, id) {
    payload.unidades = payload?.unidades
      ?.map((unidade) => {
        // eslint-disable-next-line
        if (unidade.editado == true || enviaDadosUnidades == true) {
          return unidade;
        } else {
          return null;
        }
      })
      .filter((unidade) => unidade !== null);

    payload = validaCidadeUfUnidades(payload);
    const data = await putCredenciadoUser(payload, id);
    return data;
  }

  useEffect(() => {
    async function loadDDDs() {
      const data = await getDDDs().catch(() =>
        sessionStorage.setItem('ddds', JSON.stringify(dddList)),
      );

      if (data) {
        await sessionStorage.setItem('ddds', JSON.stringify(data));
      }
    }

    if (!ddds) {
      loadDDDs();
    }
  }, [ddds]);
  

  return (
    <CredenciadoContext.Provider
      value={{
        fetchInformacoesBancos,
        dadosBancoContext,
        editarCredenciado,
        criarCredenciado,
        abasAcessiveis,
        contatoInputs,
        genericTouched,
        genericValidate,
        setContatoInputs,
        initialValuesContato,
        fileList,
        setFileList,
        validaDuasPalavras,
        formik,
        initialValuesSocios,
        validationField,
        sociosInputs,
        setSociosInputs,
        actionGetHierarquia,
        actionGetHierarquiaCargos,
        initialValuesComercial,
        actionGetPermissaoCadastroCpfCnpj,
        credenciamentoHierarquias,
        getCredenciadoArvore,
        formikComercial,
        visibleDatePickerComercial,
        setVisibleDatePickerComercial,
        valueHierarquia,
        setValueHierarquia,
        dadosCredenciamentoContext,
        pesquisarCredenciado,
        listaPesquisa,
        isCnpjValid,
        fetchArvoreUnidades,
        fetchInfoUsuario,
        infoUsuario,
        postProdutoRegiao,
        fetchUnidadesInfo,
        fileListDocumentos,
        setFileListDocumentos,
        lstRandomIdsUnidadesTree,
        setLstRandomIdsUnidadesTree,
        adicionaGrupoSearch,
        unidades,
        setUnidades,
        unidadeId,
        setUnidadeId,
        typeModal,
        setTypeModal,
        unidadeInfos,
        setUnidadeInfos,
        visibleModalUnidades,
        setVisibleModalUnidades,
        findUnidade,
        AllUnidades,
        setAllUnidades,
        credenciadoArvore,
        setCredenciadoArvore,
        unidadesFiltradas,
        setUnidadesFiltradas,
        filtroAtual,
        setFiltroAtual,
        handlePesquisar,
        findNovo,
        filtraListagem,
        hierarquiasAtualizado,
        setHierarquiasAtualizado,
        showVigencias,
        setShowVigencias,
        setHierarquiasUnidades,
        hierarquiasUnidades,
        visibleModalDiscard,
        setVisibleModalDiscard,
        loadContextCredenciado,
        propsListagem,
        produtosRegiao,
        setProdutosRegiao,
        userId,
        isCpfCnpjUnidadeValid,
        cpfCnpjUnidadesCadastradas,
        setCpfCnpjUnidadesCadastradas,
        initialValues,
        swapElement,
        inativaSubunidades,
        populaRegioes,
        enviaDadosUnidades,
        setEnviaDadosUnidades,
        estados,
        setEstados,
        cidades,
        setCidades,
        fetchCidades,
      }}
    >
      {children}
    </CredenciadoContext.Provider>
  );
}

export default CredenciadoProvider;
