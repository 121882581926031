import React, { useState, useContext, useEffect } from 'react';

import { Upload, Modal } from 'antd';
import Message from '../../../components/Modal';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import Overlay from '../../../components/Overlay';

import { carregarImagem, getLinkImagem } from '../../../services/imagemService';
import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';
import FileContentUpload from '../../../components/FileContentUpload';

function FormUploads() {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [blobPdf, setBlobPdf] = useState(null);
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const { arquivos, setArquivos, contexto, proposta, editableField } =
    useContext(AnaliseMesaContext);

  const indiceArquivo = (imagemId) =>
    arquivos.findIndex((elem) => elem.imagemId === imagemId);

  const handleType = (value, file) => {
    let index;

    if (file.imagemId) {
      index = indiceArquivo(file.imagemId);
    }

    const tempArquivos = arquivos;
    if (tempArquivos[index]?.documentoId) {
      tempArquivos[index].documentoId = value;
    }
    file.documentoId = value;
  };
  
  const handleCancel = () => {
    setPreviewVisible(false);
    setBlobPdf(null);
    setPreviewImage(null);
    setPreviewTitle(null);
  };

  const handleDownload = async (file) => {
    setLoading(true);
    const anexo = await getLinkImagem(file.link).catch(() => setLoading(false));
    const { conteudo } = anexo;
    const link = document.createElement('a');
    link.href = conteudo;
    link.setAttribute('download', file.uid);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);

    if (file.link) {
      const anexo = await getLinkImagem(file.link);
      setLoadingFile(false);

      const { conteudo } = anexo;

      if (conteudo) {
        file.type = conteudo.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(conteudo);
        setPreviewVisible(true);
      }
    } else if (file.url) {
      setLoadingFile(false);
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(file.url);
      setPreviewVisible(true);
    }

    if (file.documentoId) {
      setPreviewTitle(
        contexto?.arquivosProduto?.find((tipo) => tipo.id === file.documentoId)
          .nome,
      );
    } else {
      setPreviewTitle('');
    }
  };

  const uploadButton = (
    <div className="action-anexar">
      <i className="fa fa-plus"></i>
      <div>Clique para anexar</div>
    </div>
  );

  const beforeUpload = (file) => {
    file.status = '';
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg'
    ) {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
        'warning',
      );
      file.status = 'removed';
    }
    return false;
  };

  const uploadImage = async (options) => {
    const { file } = options;

    if (file?.status !== 'removed') {
      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const fileURL = await getBase64(file);
          const { data } = await carregarImagem(temp);
          const { dataHora, id } = data;
          const newFile = {
            dataHora: dataHora,
            documentoId: null,
            imagemId: id,
            url: fileURL,
            exclusao: true,
            type: file.type,
          };
          setArquivos((arquivos) => [newFile, ...arquivos]);
        } catch (err) {
          console.log(`Falha no upload`, err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemove = (file) => {
    if (file.exclusao) {
      let newFileList = [];

      if (file.imagemId) {
        newFileList = arquivos.filter(
          (fileRemove) => fileRemove.imagemId !== file.imagemId,
        );
      }

      setArquivos(newFileList);
    }
  };

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobPdf(blobURL);
    } else {
      setBlobPdf(null);
    }
  }, [previewImage, fileType]);

  return (
    <div>
      <Overlay active={loading}>
        <Upload
          multiple={true}
          listType="picture-card"
          className="form-upload-documentos-loja proposta-credito-uploads"
          fileList={arquivos}
          itemRender={(_, file) => {
            const existeTipoAnexo = contexto?.tipoAnexos.find(arquivo => arquivo.id === file?.documentoId);

            if(!existeTipoAnexo && file.agente === "corban"){
              file.documentoId = contexto?.tipoAnexos.find(arquivo => arquivo.nome?.toLowerCase() === "outros").id;
            }

            return (
            <FileContentUpload 
              file={file}
              editaAdicionarRemover={editableField("anexo.adicionarRemover")}
              editarTipoDocumento={editableField("documento.editarTipoDocumento")}
              handleDownload={handleDownload}
              handlePreview={handlePreview}
              handleRemove={handleRemove}
              handleType={handleType}
              proposta={proposta}
              tipoAnexos={contexto?.tipoAnexos}
            />
          )}}
          beforeUpload={beforeUpload}
          onChange={uploadImage}
          disabled={
            !contexto?.bloqueiaAcessoSimultaneo
              ? !editableField('anexo.adicionarRemover')
              : true
          }
        >
          {uploadButton}
        </Upload>

        <Modal
          visible={previewVisible}
          destroyOnClose={true}
          title={previewTitle}
          footer={null}
          className={
            'modal-preview-upload ' +
            fileType.replace('/', '') +
            ' preview-analise-mesa-credito' +
            (previewTitle ? ' com-titulo ' : '')
          }
          onCancel={handleCancel}
        >
          <Overlay active={loadingFile}>
            {!loadingFile && (
              <div className="file-preview-container">
                {fileType === 'application/pdf' && (
                  <object
                    data={`${blobPdf ?? previewImage}#toolbar=0`}
                    type={fileType}
                    width="100%"
                    height="100%"
                    aria-label="Upload File"
                  />
                )}
                {fileType !== 'application/pdf' && (
                  <div className="modal-preview-image">
                    <img src={previewImage} alt="Documento"></img>
                  </div>
                )}
              </div>
            )}
          </Overlay>
        </Modal>
      </Overlay>
    </div>
  );
}

export default FormUploads;
