import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

import { Table } from 'antd';

import { Select } from 'antd';
import { Form, Field, Input } from 'formik-antd';
import SimpleInput from '../../../components/SimpleInput';
import TooltipUnidadeConsumidora from '../../../components/TooltipUnidadeConsumidora';
import DatePicker from '../../../components/DatePicker';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';

import { ReactComponent as IconReload } from '../../../assets/images/icons/reload.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import { ReactComponent as IconExpandedTable } from '../../../assets/images/icons/icon-expanded-table.svg';
import { ReactComponent as IconCheck } from '../../../assets/images/icons/confirm.svg';
import { ReactComponent as IconNegative2 } from '../../../assets/images/icons/negative2.svg';
import { ReactComponent as IconSolicitarComprovante } from '../../../assets/images/icons/paper-lines.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { columnsHistoricoCia } from './ColumnsTables/columnsHistoricoCia';

import HistoricoCiaFormDetails from './HistoricoCiaFormDetails';

import Button from '../../../components/Button';
import HeaderCalculoLuzDia from '../../../components/HeaderCalculoLuzDia';
import SpinnerArrowCircle from '../../../components/SpinnerArrowCircle';

import { crivo } from '../../../services/crivo';
import {
  chamadaLuzEmDia,
  getHistoricoCiaAtualizada,
  getReconsultaDadosConvenio,
} from '../../../services/propostaService';

import Modal from '../../../components/Modal';
import { ReactComponent as IconEditar } from '../../../assets/images/icons/icon-editar.svg';

import './styles.scss';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import SelectSearch from '../../../components/SearchSelect';
import TooltipComponent from '../../../components/tooltip';
import { isBoolean, isNumber } from 'lodash';
import { currencyFormat } from '../../../utils/numberFormatter';
import { TooltipReconsulta } from '../../../components/TooltipReconsulta';

const { Option } = Select;

function HistoricoCiaForm({
  values,
  contexto,
  carregarImagem,
  debitosConveniada,
  setDebitosConveniada,
  clienteElegivel,
  setClienteElegivel,
  editableField,
}) {
  const {
    formik,
    proposta,
    highlightDadoAdicional,
    tentativaMudancaSituacaoAprovado,
    limiteCliente,
    valorAjusteParcelaProposta,
    setValorAjusteParcelaProposta,
    setValidaLimiteNaoPermitido,
    setValidaClienteSemMargem,
    setValidaClienteSemMargemOutroProduto,
    menorValorLimiteParcela,
    objetoLimitesParcela,
    valorMaximoParcela,
    validaCompanhiaCampoLimiteParcela,
    validaDadoAdicionalUc,
    setValidaDadoAdicionalUc,
    dadoAdicionalUcIndex,
  } = useContext(AnaliseMesaContext);

  const { setFieldValue, validateForm } = formik;
  const [loading, setLoading] = useState(false);
  const [loadingReconsulta, setLoadingReconsulta] = useState(false);
  const [disableBtnValidador, setDisableBtnValidador] = useState(false);
  const [debitosPagar, setDebitosPagar] = useState(0);

  const [valorDisponivel, setValorDisponivel] = useState(0);

  const [qtdDebitos, setQtdDebitos] = useState(-1);
  const [validadoCrivo, setValidadoCrivo] = useState(true);
  const [faturaDebitoConsiderado, setFaturaDebitoConsiderado] = useState(true);
  const selectSimNao = [
    { nome: 'Sim', valor: true },
    { nome: 'Não', valor: false },
  ];
  const LUZ_EM_DIA = 12;

  const novaFatura = {
    participa: true,
    codigo: null,
    codigoBarras: null,
    comprovante: null,
    consumo: null,
    dataReferencia: moment(new Date()).format('MM/YYYY'),
    dataVencimento: new Date(),
    id: null,
    descontar: false,
    situacao: 1,
    tipo: 0,
    valor: 0,
    valorCalculo: 0,
    faturaValidada: false
  };

  const validaDadosAdicionais = () => {
    if (values?.operacao?.dadosAdicionais) {
      values?.operacao?.dadosAdicionais?.forEach((dado, i) => {
        values.operacao.dadosAdicionais[i].tooltip =
          highlightDadoAdicional(dado);
      });
    }
  };

  useEffect(() => {
    validarClienteElegivel(debitosConveniada);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitosConveniada]);

  const adicionarFatura = () => {
    novaFatura.id = String(debitosConveniada.length + 1);
    const debitosConveniadaAtualizado = [...debitosConveniada, novaFatura];
    setDebitosConveniada(debitosConveniadaAtualizado);
    validarClienteElegivel(debitosConveniadaAtualizado);
    setQtdDebitos(qtdDebitos - 1);
  };

  const removerFatura = (item) => {
    const debitosConveniadaAtualizado = debitosConveniada.filter(
      (debito) => debito.id !== item.id,
    );
    setDebitosConveniada(debitosConveniadaAtualizado);
    setValidadoCrivo(false);
    setQtdDebitos(qtdDebitos - 1);
    validarClienteElegivel(debitosConveniadaAtualizado);
  };

  useEffect(() => {
    if (values && clienteElegivel && clienteElegivel !== -1) {
      values.elegivelLuzEmDia = clienteElegivel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteElegivel]);

  useEffect(() => {
    let menorValorLimite = Math.min(
      values?.operacao?.produtoId === LUZ_EM_DIA &&
        debitosPagar > 0 &&
        clienteElegivel === 0
        ? values?.resultadoCrivo?.valorLuzEmDia
        : values?.resultadoCrivo?.limite,
      values?.operacao?.valorContratado,
    );

    if (
      !values?.operacao?.limite &&
      !(
        values?.operacao?.produtoId === LUZ_EM_DIA &&
        debitosPagar > 0 &&
        clienteElegivel === 0 &&
        values?.resultadoCrivo?.valorLuzEmDia
      )
    ) {
      menorValorLimite = values?.operacao?.valorContratado;
    }

    setValorDisponivel(menorValorLimite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitosPagar]);

  useEffect(() => {
    if (values && (valorDisponivel || debitosPagar)) {
      values.operacao.valorLiberado = valorDisponivel - debitosPagar;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorDisponivel, debitosPagar]);

  useEffect(() => {
    setValidadoCrivo(false);
    const somaDebitos = debitosConveniada?.reduce(
      (acc, faturaAtual) =>
        acc +
        (faturaAtual.descontar && faturaAtual.participa
          ? faturaAtual.valor
          : 0),
      0,
    );
    setDebitosPagar(parseFloat(somaDebitos.toFixed(2)));
    setDisableBtnValidador(debitosConveniada?.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, debitosConveniada?.length]);

  const validarLuzEmDia = async () => {
    setLoading(true);
    setDisableBtnValidador(true);
    const payloadCrivo = {};

    payloadCrivo.id = values?.id;
    payloadCrivo.propostaId = values?.id;
    payloadCrivo.faturas = debitosConveniada;

    const conexaoCrivo = await chamadaLuzEmDia(payloadCrivo);
    if (conexaoCrivo) {
      const response = await crivo({
        ...conexaoCrivo.data,
        propostaId: values?.id,
      });
      let valorLimiteParcelaAtualizadoEnergia;
      let valorLimiteParcelaAtualizadoCdcEnergia;
      if (response) {
        if (response.statusCode === 400) {
          Modal('', response.messages.join(' \n \n '), 'warning');
        } else {
          if (response?.data?.mensagem === 'LT') {
            setClienteElegivel(3);
          } else if (response?.data?.mensagem === 'SC') {
            setClienteElegivel(1);
          } else if (response?.data?.mensagem === 'LD') {
            setClienteElegivel(0);
          }
          setValidadoCrivo(true);
          validarClienteElegivel(debitosConveniada);
          if (
            proposta?.operacao?.produtoNome === 'Energia' ||
            proposta?.operacao?.produtoNome === 'Luz em Dia'
          ) {
            valorLimiteParcelaAtualizadoEnergia =
              isNumber(response?.data?.valorAjusteParcela) &&
                response?.data?.valorAjusteParcela >= 0 &&
                response?.data?.valorAjusteParcela < valorMaximoParcela
                ? response?.data?.valorAjusteParcela
                : valorMaximoParcela;

            setValorAjusteParcelaProposta(valorLimiteParcelaAtualizadoEnergia);
          } else {
            valorLimiteParcelaAtualizadoCdcEnergia =
              isNumber(response?.data?.valorAjusteParcela) &&
                response?.data?.valorAjusteParcela >= 0 &&
                response?.data?.valorAjusteParcela <
                objetoLimitesParcela[0].limiteParcela
                ? response?.data?.valorAjusteParcela
                : objetoLimitesParcela[0].limiteParcela;
            setValorAjusteParcelaProposta(
              valorLimiteParcelaAtualizadoCdcEnergia,
            );
          }
          debitosConveniada?.forEach((debito) => {
            debito.faturaValidada = true;
          })
        }
      }

      setLoading(false);
      setDisableBtnValidador(false);

      if (
        (proposta?.operacao?.produtoNome === 'Energia' ||
          proposta?.operacao?.produtoNome === 'Luz em Dia') &&
        valorLimiteParcelaAtualizadoEnergia < menorValorLimiteParcela
      ) {
        Modal(
          '',
          'Limite de prestação é invalido e não atende a regra do produto',
          'warning',
        );
        setValidaClienteSemMargem(true);
      } else if (
        proposta.operacao?.produtoNome === 'CDC Energia' &&
        (objetoLimitesParcela[0].minimoParcelaCrivo
          ? valorLimiteParcelaAtualizadoCdcEnergia <
          objetoLimitesParcela[0].minimoParcelaCrivo
          : false)
      ) {
        Modal(
          '',
          'Limite de prestação é invalido e não atende a regra do produto',
          'warning',
        );
        setValidaClienteSemMargemOutroProduto(true);
      } else if (
        (proposta.operacao?.produtoNome === 'Energia' ||
          proposta.operacao?.produtoNome === 'Luz em Dia') &&
        valorLimiteParcelaAtualizadoEnergia < proposta.operacao.prestacao
      ) {
        Modal(
          '',
          'Limite de prestação é invalido, é necessário ajustar o valor da parcela',
          'warning',
        );
        setValidaLimiteNaoPermitido(true);
      } else if (
        proposta.operacao?.produtoNome === 'CDC Energia' &&
        valorLimiteParcelaAtualizadoCdcEnergia < proposta.operacao.prestacao
      ) {
        Modal(
          '',
          'Limite de prestação é invalido, é necessário ajustar o valor da parcela',
          'warning',
        );
        setValidaLimiteNaoPermitido(true);
      }
    }
  };

  const validarClienteElegivel = (debitosConveniadaAtualizado) => {
    let qntdFaturasDebConsiderado = 0;
    let menorIdDebito = 0;

    debitosConveniadaAtualizado?.forEach((item) => {
      item.participa === true && qntdFaturasDebConsiderado++;
      menorIdDebito = Math.min(menorIdDebito, item.id);
    });
    setQtdDebitos(menorIdDebito - 1);

    if (qntdFaturasDebConsiderado === 0) {
      setClienteElegivel(3);
      setFaturaDebitoConsiderado(false);
    } else {
      setFaturaDebitoConsiderado(true);
    }
  };

  const handleReconsulta = async () => {
    setLoadingReconsulta(true);
    const response = await getReconsultaDadosConvenio(proposta.id);

    setLoadingReconsulta(false);

    if (!response?.respostaCrivo?.mensagem) {
      const responseHistoricoCiaAtualizado = await getHistoricoCiaAtualizada(
        proposta.id,
      );

      // Caso o backend retorne "null" para algum campo, o campo será limpo.
      Object.keys(responseHistoricoCiaAtualizado?.historicoCia).forEach(key => {
        if (responseHistoricoCiaAtualizado?.historicoCia[key] === null)
          responseHistoricoCiaAtualizado.historicoCia[key] = undefined;
      });

      setFieldValue("historicoCia", responseHistoricoCiaAtualizado?.historicoCia)
      setDebitosConveniada(responseHistoricoCiaAtualizado?.historicoCia?.debitosConveniada || []);
    } else {
      Modal("", response?.respostaCrivo?.mensagem, "warning")
      const responseHistoricoCiaAtualizado = await getHistoricoCiaAtualizada(
        proposta.id,
      );
      if (responseHistoricoCiaAtualizado?.historicoCia?.historicoP2Reconsulta) {
        setFieldValue("historicoCia.historicoP2Reconsulta",
          responseHistoricoCiaAtualizado?.historicoCia?.historicoP2Reconsulta)
      }
    }
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tentativaMudancaSituacaoAprovado]);

  useEffect(() => {
    if (validaDadoAdicionalUc && dadoAdicionalUcIndex >= 0) {
      formik.setFieldTouched(
        `operacao.dadosAdicionais.${dadoAdicionalUcIndex}.valor`,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validaDadoAdicionalUc]);

  return (
    <div className="row historico-form">
      <div className="col-lg-12 topo">
        {values?.operacao?.convenioNome !== null &&
          values?.operacao?.dadosAdicionais?.length > 0 && (
            <div className="dados-adicionais-convenio">
              <div className="form-row item-convenio">
                <div
                  className={
                    values?.operacao?.convenioGrupoNeoEnergia
                      ? 'flex-3'
                      : 'flex-1'
                  }
                >
                  <Form.Item
                    name="operacao.convenioNome"
                    label="Companhia Elétrica"
                  >
                    <Field name="operacao.convenioNome">
                      {({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={
                            !contexto?.bloqueiaAcessoSimultaneo
                              ? !editableField('historico.dadosHistoricoCia')
                              : true
                          }
                          readOnly
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                {values?.operacao?.dadosAdicionais?.map((dadoAdicional, i) => {
                  validaDadosAdicionais();
                  return (
                    <div className="flex-1" key={i}>
                      <Form.Item
                        name={`operacao.dadosAdicionais.${i}.valor`}
                        label={
                          <>
                            <label>{dadoAdicional?.nome}</label>
                            {values?.operacao?.dadosAdicionais[i]?.tooltip && (
                              <TooltipUnidadeConsumidora
                                data={values?.operacao?.dadosAdicionais[i]}
                              />
                            )}
                            {i === dadoAdicionalUcIndex &&
                              values?.historicoCia
                                ?.dadoAdicionalNomeAnalista && (
                                <TooltipComponent
                                  title={
                                    <>
                                      Preenchido por:
                                      <strong>
                                        {
                                          values?.historicoCia
                                            ?.dadoAdicionalNomeAnalista
                                        }
                                      </strong>
                                    </>
                                  }
                                  placement="bottom"
                                  className="tooltip-info"
                                  overlayClassName="tooltip-info"
                                  color="#435361"
                                >
                                  <IconEditar className="icon-edit" />
                                </TooltipComponent>
                              )}
                          </>
                        }
                        hasFeedback
                        className={
                          values?.operacao?.dadosAdicionais[i]?.tooltip &&
                          `campo-existente`
                        }
                        validate={(value) => {
                          const shouldValidate = !!editableField(
                            'historico.dadosHistoricoCia',
                          );

                          if (
                            validaDadoAdicionalUc &&
                            i === dadoAdicionalUcIndex
                          ) {
                            return dadoAdicional?.mensagem;
                          }

                          if (
                            shouldValidate &&
                            !RegExp(dadoAdicional?.formato).test(value) &&
                            dadoAdicional?.tipo !== 4 &&
                            tentativaMudancaSituacaoAprovado
                          ) {
                            return dadoAdicional?.mensagem;
                          }
                        }}
                      >
                        {dadoAdicional?.tipo !== 4 ? (
                          <Field name={`operacao.dadosAdicionais.${i}.valor`}>
                            {({ field }) => (
                              <Input
                                {...field}
                                defaultValue={dadoAdicional?.valor}
                                className="ant-input"
                                disabled={
                                  !contexto?.bloqueiaAcessoSimultaneo
                                    ? !editableField(
                                      'historico.dadosHistoricoCia',
                                    )
                                    : true
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `operacao.dadosAdicionais.${i}.valor`,
                                    e.target.value,
                                  );

                                  const isEmAnalise =
                                    proposta?.situacaoDescricao ===
                                    'Em An\u00e1lise';

                                  if (
                                    dadoAdicionalUcIndex === i &&
                                    isEmAnalise &&
                                    proposta?.resultadoCrivo?.unidadeCorreta ===
                                    false
                                  ) {
                                    setValidaDadoAdicionalUc(false);
                                    const id = localStorage.getItem('userId');
                                    const nome = localStorage.getItem('nome');
                                    setFieldValue(
                                      'historicoCia.dadoAdicionalAnalistaId',
                                      parseInt(id),
                                    );
                                    setFieldValue(
                                      'historicoCia.dadoAdicionalNomeAnalista',
                                      nome,
                                    );
                                  }
                                }}
                                onBlur={(e) => {
                                  if (
                                    !RegExp(dadoAdicional?.formato).test(
                                      e.target.value,
                                    )
                                  ) {
                                    setFieldValue(
                                      `operacao.dadosAdicionais.${i}.valor`,
                                      '',
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="00/00/0000"
                            prefix={<CalendarIcon className="mr-2" />}
                            name={`operacao.dadosAdicionais.${i}.valor`}
                            disabled={
                              !contexto?.bloqueiaAcessoSimultaneo
                                ? !editableField('historico.dadosHistoricoCia')
                                : true
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                })}
                {!values?.operacao?.convenioGrupoNeoEnergia && (
                  <>
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.fornecimento"
                        label={
                          <>
                            <label>Fornecimento</label>
                            {values?.historicoCia?.fornecimentoNomeAnalista && (
                              <TooltipComponent
                                title={
                                  <>
                                    Preenchido por:
                                    <strong>
                                      {
                                        values?.historicoCia
                                          ?.fornecimentoNomeAnalista
                                      }
                                    </strong>
                                  </>
                                }
                                placement="bottom"
                                className="tooltip-info"
                                overlayClassName="tooltip-info"
                                color="#435361"
                              >
                                <BulletInfo className="bullet-info-icon" />
                              </TooltipComponent>
                            )}
                          </>
                        }
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.fornecimento"
                          onChange={(value) => {
                            setFieldValue('historicoCia.fornecimento', value);
                            const id = localStorage.getItem('userId');
                            const nome = localStorage.getItem('nome');
                            setFieldValue(
                              'historicoCia.fornecimentoAnalistaId',
                              parseInt(id),
                            );
                            setFieldValue(
                              'historicoCia.fornecimentoNomeAnalista',
                              nome,
                            );
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          suffixIcon={<IconArrowSelect />}
                          value={values?.historicoCia?.fornecimento}
                          disabled={
                            !editableField('historico.dadosHistoricoCia')
                          }
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.titularidade"
                        label={
                          <>
                            <label>Titularidade</label>
                            {values?.historicoCia?.titularidadeNomeAnalista && (
                              <TooltipComponent
                                title={
                                  <>
                                    Preenchido por:{' '}
                                    <strong>
                                      {
                                        values?.historicoCia
                                          ?.titularidadeNomeAnalista
                                      }
                                    </strong>
                                  </>
                                }
                                placement="bottom"
                                className="tooltip-info"
                                overlayClassName="tooltip-info"
                                color="#435361"
                              >
                                <BulletInfo className="bullet-info-icon" />
                              </TooltipComponent>
                            )}
                          </>
                        }
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.titularidade"
                          onChange={(value) => {
                            setFieldValue('historicoCia.titularidade', value);
                            const id = localStorage.getItem('userId');
                            const nome = localStorage.getItem('nome');
                            setFieldValue(
                              'historicoCia.titularidadeAnalistaId',
                              parseInt(id),
                            );
                            setFieldValue(
                              'historicoCia.titularidadeNomeAnalista',
                              nome,
                            );
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          suffixIcon={<IconArrowSelect />}
                          value={values?.historicoCia?.titularidade}
                          disabled={
                            !editableField('historico.dadosHistoricoCia')
                          }
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                  </>
                )}
                {!!values?.operacao?.convenioGrupoNeoEnergia && (
                  <div className="form-row item-convenio">
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.fornecimento"
                        label={
                          <>
                            <label>Fornecimento</label>
                            {values?.historicoCia?.fornecimentoNomeAnalista && (
                              <TooltipComponent
                                title={
                                  <>
                                    Preenchido por:
                                    <strong>
                                      {
                                        values?.historicoCia
                                          ?.fornecimentoNomeAnalista
                                      }
                                    </strong>
                                  </>
                                }
                                placement="bottom"
                                className="tooltip-info"
                                overlayClassName="tooltip-info"
                                color="#435361"
                              >
                                <BulletInfo className="bullet-info-icon" />
                              </TooltipComponent>
                            )}
                          </>
                        }
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.fornecimento"
                          onChange={(value) => {
                            setFieldValue('historicoCia.fornecimento', value);
                            const id = localStorage.getItem('userId');
                            const nome = localStorage.getItem('nome');
                            setFieldValue(
                              'historicoCia.fornecimentoAnalistaId',
                              parseInt(id),
                            );
                            setFieldValue(
                              'historicoCia.fornecimentoNomeAnalista',
                              nome,
                            );
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          suffixIcon={<IconArrowSelect />}
                          value={values?.historicoCia?.fornecimento}
                          disabled={!editableField('historico.dadosHistoricoCia')}
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.titularidade"
                        label={
                          <>
                            <label>Titularidade</label>
                            {values?.historicoCia?.titularidadeNomeAnalista && (
                              <TooltipComponent
                                title={
                                  <>
                                    Preenchido por:{' '}
                                    <strong>
                                      {
                                        values?.historicoCia
                                          ?.titularidadeNomeAnalista
                                      }
                                    </strong>
                                  </>
                                }
                                placement="bottom"
                                className="tooltip-info"
                                overlayClassName="tooltip-info"
                                color="#435361"
                              >
                                <BulletInfo className="bullet-info-icon" />
                              </TooltipComponent>
                            )}
                          </>
                        }
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.titularidade"
                          onChange={(value) => {
                            setFieldValue('historicoCia.titularidade', value);
                            const id = localStorage.getItem('userId');
                            const nome = localStorage.getItem('nome');
                            setFieldValue(
                              'historicoCia.titularidadeAnalistaId',
                              parseInt(id),
                            );
                            setFieldValue(
                              'historicoCia.titularidadeNomeAnalista',
                              nome,
                            );
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          suffixIcon={<IconArrowSelect />}
                          value={values?.historicoCia?.titularidade}
                          disabled={!editableField('historico.dadosHistoricoCia')}
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.acordo"
                        label="Acordo"
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.acordo"
                          onChange={(value) => {
                            setFieldValue('historicoCia.acordo', value);
                          }}
                          value={values?.historicoCia?.acordo}
                          disabled={!editableField('historico.dadosHistoricoCia')}
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <Form.Item
                        name="historicoCia.parcelamento"
                        label="Parcelamento"
                        validate={(value) => {
                          if (
                            tentativaMudancaSituacaoAprovado &&
                            !isBoolean(value)
                          ) {
                            return 'Campo Obrigatório';
                          }
                        }}
                      >
                        <SelectSearch
                          name="historicoCia.parcelamento"
                          onChange={(value) => {
                            setFieldValue('historicoCia.parcelamento', value);
                          }}
                          value={values?.historicoCia?.parcelamento}
                          disabled={!editableField('historico.dadosHistoricoCia')}
                        >
                          {selectSimNao?.map((item) => (
                            <Option key={item.valor} value={item.valor}>
                              {item.nome}
                            </Option>
                          ))}
                        </SelectSearch>
                      </Form.Item>
                    </div>
                    <div className="flex-1 container-reconsulta">
                      <TooltipReconsulta
                        data={values?.historicoCia?.historicoP2Reconsulta || []}
                      >
                        <Button
                          className="reconsulta-button"
                          onClick={handleReconsulta}
                          disabled={
                            !editableField('historico.dadosHistoricoCia') ||
                            loadingReconsulta ||
                            (formik.values?.operacao?.produtoId === LUZ_EM_DIA &&
                              formik.values?.historicoCia?.debitosConveniada?.some(
                                (value) => value.descontar
                            ))
                          }
                        >
                          {loadingReconsulta ? <SpinnerArrowCircle /> : <IconReload />} <span>RECONSULTAR</span>
                        </Button>
                      </TooltipReconsulta>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
      <div className="col-lg-12 topo header-historicoCia">
        <div className="valida-luz-em-dia-energia-tradicional-container">
          <Button
            className="btn-validar-luz"
            variant="blue"
            onClick={() => validarLuzEmDia()}
            disabled={
              contexto?.bloqueiaAcessoSimultaneo
                ? true
                : editableField('validarLuzEmdia')
                  ? disableBtnValidador
                  : true
            }
          >
            {loading ? <SpinnerArrowCircle /> : <IconReload />}
            {values?.operacao?.permiteLuzEmDia
              ? 'Validar Luz em dia'
              : 'Validar Histórico Cia'}
          </Button>
          <Select
            value={clienteElegivel}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              setClienteElegivel(value);
            }}
            dropdownMatchSelectWidth="true"
            className="select-status"
            disabled={
              editableField('classificacaoFaturaEnergia')
                ? !faturaDebitoConsiderado || !validadoCrivo
                : true
            }
            showArrow={faturaDebitoConsiderado && validadoCrivo}
          >
            {values?.operacao?.permiteLuzEmDia && (
              <Option key={0} value={0}>
                <h5 className="info-status elegivel">
                  <IconCheck /> Cliente Luz Em Dia
                </h5>
              </Option>
            )}
            <Option key={1} value={1}>
              <h5 className="info-status comprovante">
                <IconSolicitarComprovante /> Solicitar Comprovante
              </h5>
            </Option>
            <Option key={3} value={3}>
              <h5 className="info-status nao-elegivel">
                <IconNegative2 /> Energia Tradicional
              </h5>
            </Option>
          </Select>
        </div>
        <div className="limite-parcela-limite-cliente-container">
          {validaCompanhiaCampoLimiteParcela && (
            <div className="limite-parcela">
              Limite da parcela:
              <span style={{ color: '#686B6D' }}>
                {currencyFormat(valorAjusteParcelaProposta || 0)}
              </span>
            </div>
          )}
          <div className="limite-cliente">
            Limite do Cliente:
            <span style={{ color: '#0DBF0D' }}>
              {currencyFormat(limiteCliente)}
            </span>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <HeaderCalculoLuzDia
          valorDisponivel={valorDisponivel}
          debitosPagar={debitosPagar}
        />
      </div>
      <div className="col-lg-12">
        <Table
          columns={columnsHistoricoCia(
            contexto,
            validaCompanhiaCampoLimiteParcela,
          )}
          dataSource={debitosConveniada}
          expandable={{
            expandedRowRender: (row, i) => (
              <HistoricoCiaFormDetails
                row={row}
                contexto={contexto}
                validarClienteElegivel={validarClienteElegivel}
                carregarImagem={carregarImagem}
                removerFatura={removerFatura}
                editableField={editableField}
                values={values}
                debitosConveniada={debitosConveniada}
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <Button
                className={'btn-expanded-row ' + (expanded && 'expanded-open')}
              >
                <IconExpandedTable onClick={(e) => onExpand(record, e)} />
              </Button>
            ),
          }}
          expandIconColumnIndex={9}
          rowKey={'id'}
          pagination={false}
        />

        <Button
          className="btn-adicionar-fatura"
          variant="blue"
          onClick={() => adicionarFatura()}
          disabled={
            !contexto?.bloqueiaAcessoSimultaneo
              ? !editableField('debitosConveniada.adicionarFatura')
              : true
          }
        >
          <IconPlusBtn /> Fatura
        </Button>
      </div>
    </div>
  );
}

export default HistoricoCiaForm;
